let livemode=true;
let baseUrl='';
let logoUrl ='';
let Flagurl ='';
let KYCurl ='';

if (livemode) {
    baseUrl='https://www.shreebanshidharlogistics.com/api/';
    logoUrl ='https://cdn-x.shreebanshidharlogistics.com/Logo/';
    Flagurl = 'https://cdn-x.shreebanshidharlogistics.com/Flag/';
    KYCurl = 'https://cdn-x.shreebanshidharlogistics.com/KYC/';
    
} else {
    baseUrl='http://192.168.29.181:8000/api/';
    logoUrl ='https://cdn-x.shreebanshidharlogistics.com/Logo/';
    Flagurl = 'https://cdn-x.shreebanshidharlogistics.com/Flag/';
    KYCurl = 'https://cdn-x.shreebanshidharlogistics.com/KYC/';
}
console.log("///////////",livemode);
let Global={
    baseUrl:baseUrl,
    logoUrl:logoUrl,
    flagurl:Flagurl,
    kyc:KYCurl,
    timezone:'Asia/Kolkata'
};
export default Global;